<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Lastik Oteli</span>
        </template>
        <item-form />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import ItemForm from '@/views/Spareparts/TireHotel/ItemForm.vue'
import Customer from '@/views/Crm/View/Customer.vue'
import AddCar from '@/views/Service/components/AddCar.vue'

export default {
  name: 'Index',
  components: {
    AddCar,
    BTabs,
    Customer,
    BTab,
    ItemForm,
  },
  computed: {
    dataItem() {
      return this.$store.getters['tireHotel/dataItem']
    },
    tireSizes() {
      return this.$store.getters['tireSizes/getTireSizes']
    },
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrands']
    },
    tireTypes() {
      return this.$store.getters['tireTypes/getTireTypes']
    },
    tireShelfs() {
      return this.$store.getters['tireShelfs/getTireShelfs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    saveStatus() {
      return this.$store.getters['tireHotel/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('tireHotel/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.getCustomer(response.id_com_customer)
            this.getCars(response.id_com_customer)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCars(id) {
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': id,
        },
      })
    },
  },
}
</script>
